.accountpage {
  height: 100vh;
}

.input_selection {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
