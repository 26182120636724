@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: white;
  color: rgb(0, 0, 0);
}

.btn {
  display: inline-block;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}
