@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@700&display=swap");

/* .active {
  background-color: rgb(202, 187, 189);
}

nav ul li a:not(.active):hover {
  background-color: transparent;
}
 */
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  position: sticky;
  top: 0;
  border-bottom: 1px solid white;
  z-index: 2;
}

nav .title {
  display: flex;
  justify-content: flex-start;
  font-size: 1.3rem;
  text-decoration: none;
  color: rgb(0, 0, 0);
  width: 35%;
  font-family: "Kanit", sans-serif;
  flex-grow: 2;
}

nav ul {
  display: flex;
  margin: 1%;
  font-size: 1.3rem;
  font-family: sans-serif;
  flex-wrap: no-wrap;
  font-family: "Kanit";
}

nav ul li {
  list-style: none;
}

nav ul li a {
  display: block;
  text-decoration: none;
  color: rgb(0, 0, 0);
  padding: 0.5rem;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
}

nav .menu {
  display: none;
  position: absolute;
  top: 1.3rem;
  right: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.25rem;
  height: 2rem;
}

nav .menu span {
  height: 0.4rem;
  width: 100%;
  background-color: #000000;
  border-radius: 0.2rem;
}

nav .btnlogout {
  display: flex;
  justify-content: center;
  padding: 7.25px;
  border: 0px;
  color: rgb(0, 0, 0);
  background-color: transparent;
}

.drop {
  margin-top: 8px;
}

@media (max-width: 1000px) {
  nav .menu {
    display: flex;
  }

  nav {
    flex-direction: column;
    align-items: flex-start;
  }

  .dropdown-menu.show {
    margin-left: 35%;
  }

  nav ul {
    display: none;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.25rem;
  }

  nav ul.open {
    display: flex;
    padding: 0px;
  }

  nav ul li {
    width: 100%;
    text-align: center;
  }

  nav ul li a {
    margin: 0.2rem 0.5rem;
  }

  nav .title {
    display: none;
  }
}
