.footer {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
  background-color: black;
  color: white;
}

.contactme {
  display: flex;
  flex-direction: column;
  margin-top: 1%;
  width: 40%;
}

.btn_contactme_submit {
  width: 20%;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: rgb(11, 118, 219) !important;
  color: white !important;
  font-family: "Kanit";
}

.socials {
  font-size: 3rem;
  margin: 20px;
  color: white;
}

@media (max-width: 768px) {
  .btn_contactme_submit {
    width: auto;
  }
  .contactme {
    width: auto;
  }
}
