/* Import Google font - Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.wrapper {
  padding: 35px 40px;
  border-radius: 20px;
  background: #141414;
  width: 33%;
  margin: 0% auto;
  margin-top: 25px;
  margin-bottom: 25px;
}

.wrapper header {
  display: flex;
  color: #b2b2b2;
  align-items: center;
  justify-content: space-between;
}
header h2 {
  font-size: 1.6rem;
}
header .column {
  display: flex;
  align-items: center;
}
header span {
  font-weight: 500;
  margin-right: 15px;
  font-size: 1.19rem;
}
header input {
  outline: none;
  border-radius: 30px;
}
.volume-slider input {
  accent-color: #fff;
}
.keys-checkbox input {
  height: 30px;
  width: 60px;
  cursor: pointer;
  appearance: none;
  position: relative;
  background: #4b4b4b;
}
.keys-checkbox input::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #8c8c8c;
  transform: translateY(-50%);
  transition: all 0.3s ease;
}
.keys-checkbox input:checked::before {
  left: 35px;
  background: #fff;
}
.piano-keys {
  display: flex;
  list-style: none;
  margin-top: 40px;
  padding-left: 0px;
  justify-content: space-evenly;
}
.piano-keys .key {
  cursor: pointer;
  user-select: none;
  position: relative;
  text-transform: uppercase;
}
.piano-keys .black {
  z-index: 2;
  width: 45px;
  height: 140px;
  margin: 0 -22px 0 -22px;
  border-radius: 0 0 5px 5px;
  background: linear-gradient(#333, #000);
}
.piano-keys .black.active {
  box-shadow: inset -5px -10px 10px rgba(255, 255, 255, 0.1);
  background: linear-gradient(to bottom, #000, #434343);
}
.piano-keys .white {
  height: 230px;
  width: 75px;
  border-radius: 8px;
  border: 1px solid #000;

  background: linear-gradient(#fff 96%, #eee 4%);
}
.piano-keys .white.active {
  box-shadow: inset -5px 5px 20px rgba(0, 0, 0, 0.7);
  background: linear-gradient(to bottom, #fff 0%, #eee 100%);
}
.piano-keys .key span {
  position: absolute;
  bottom: 20px;
  width: 100%;
  color: #a2a2a2;
  font-size: 1.13rem;
  text-align: center;
}
.piano-keys .key.hide span {
  display: none;
}
.piano-keys .black span {
  bottom: 13px;
  color: #888888;
}

@media screen and (max-width: 768px) {
  .wrapper {
    padding: 20px;
    width: 95%;
  }
}

@media screen and (max-width: 1440px) {
  .wrapper {
    padding: 25px;
    width: 40%;
  }
}

@media screen and (max-width: 1024px) {
  .wrapper {
    padding: 25px;
    width: 60%;
  }
  .volume-slider input {
    display: none;
  }
  .volume-slider span {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .wrapper {
    padding: 20px;
    width: 75%;
  }
}

@media screen and (max-width: 429px) {
  .wrapper {
    padding: 20px;
    width: 95%;
  }
}
