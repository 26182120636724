.quizpage {
  height: 100vh;
}

.quiztitle {
  margin-top: 1%;
  justify-content: center;
  display: flex;
  text-align: center;
  font-size: 45px;
  font-weight: bold;
}
.quiztext,
.quiz_type,
.inputsquiz {
  justify-content: center;
  display: flex;
  text-align: center;
  font-size: 21.06px;
}

.inputquiz {
  display: flex;
  justify-content: center;
  margin: 5px;
  width: 43px;
  height: 40px;
  display: block;
  border-radius: 10px;
}

.owncorrect {
  background-color: rgb(46, 194, 46);
}

.ownincorrect {
  background-color: rgb(247, 31, 31);
}

.timer,
.score {
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  margin: 2px;
}

.keyselection-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.keyselection-div,
.quiz_class {
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  .quizinfo {
    width: 40%;
    margin-left: 30%;
  }
}

@media (max-width: 768px) {
  .quiztitle {
    margin-top: 1%;
    justify-content: center;
    display: flex;
    text-align: center;
    font-size: 27px;
    font-weight: bold;
  }

  .quiztext {
    justify-content: center;
    display: flex;
    text-align: center;
    font-size: 18px;
  }

  .quizpage {
    height: 100%;
  }
}
